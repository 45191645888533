<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                  <b-form  @submit.prevent="handleSubmit(register)" >
                    <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                          </template>
                        <b-form-select
                          plain
                          v-model="schemeFormAffidavit.org_id"
                          :options="orgList"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Scheme Type"  vid="scheme_type_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('irrigation_config.scheme_typel')"
                        label-for="scheme_type_id"
                        slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('irrigation_config.scheme_typel')}} <span class="text-danger">*</span>
                          </template>
                        <b-form-select
                          plain
                          v-model="schemeFormAffidavit.scheme_type_id"
                          :options="schemeTypeList"
                          id="scheme_type_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Affidavit (En)" vid='affidavit' rules="required">
                      <b-form-group
                        class="row overflow-hidden"
                        label-cols-sm="3"
                        label-for="affidavit"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('irrigation_config.affidavit')}} <span class="text-danger">*</span>
                      </template>
                        <vue-editor
                          plain
                          v-model="schemeFormAffidavit.affidavit"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></vue-editor>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Affidavit (Bn)" vid='affidavit_bn' rules="required">
                      <b-form-group
                        class="row overflow-hidden"
                        label-cols-sm="3"
                        label-for="affidavit_bn"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('irrigation_config.affidavit_bn')}} <span class="text-danger">*</span>
                      </template>
                    <vue-editor
                      plain
                      id="affidavit_bn"
                      v-model="schemeFormAffidavit.affidavit_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></vue-editor>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { schemeFormAffidavitStore, schemeFormAffidavitUpdate } from '../../api/routes'
import { VueEditor } from 'vue2-editor'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider,
    VueEditor
  },
  created () {
    if (this.id) {
      const tmp = this.getSchemeFormAffidavitData()
      this.schemeFormAffidavit = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      schemeFormAffidavit: {
        org_id: '0',
        scheme_type_id: '0',
        title: '',
        title_bn: '',
        affidavit: '',
        affidavit_bn: '',
        created_by: 1,
        updated_by: 1
      },
      schemeTypeList: []
    }
  },
  computed: {
    orgList: function () {
     return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  },
  watch: {
    'schemeFormAffidavit.org_id': function (newVal, oldVal) {
        this.schemeTypeList = this.getSchemeTypeList(newVal)
    }
  },
  methods: {
    getSchemeFormAffidavitData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }

      if (this.schemeFormAffidavit.id) {
        this.schemeFormAffidavit.updated_by = 1
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${schemeFormAffidavitUpdate}/${this.id}`, this.schemeFormAffidavit)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, schemeFormAffidavitStore, this.schemeFormAffidavit)
      }

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('IrriConfig/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getSchemeTypeList (orgId) {
      const schemeTypeList = this.$store.state.IrriConfig.commonObj.schemeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return schemeTypeList.filter(schemeType => (schemeType.org_id === orgId && schemeType.status === 0))
      }
      return schemeTypeList
    }
  }
}
</script>
<style>
  #affidavit , #affidavit_bn {
    height: 150px
  }
</style>
